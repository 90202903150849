
import Logo from "@/components/Logo/index.vue"
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { isValidUsername } from '@/utils/validate'
import { AuthModule } from '@/store/modules/auth'
import store from '@/store'
import { LoginDto } from "@/api-client"
import handleError from "@/utils/handleError"

@Component({
  name: 'Login',
  components: {
    Logo
  }
})
export default class extends Vue {
  private validateEmail = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Email is required'))
    }
    else {
      callback()
    }
  }

  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 1) {
      callback(new Error('Password is required'))
    }
    else if (value.length < 8) {
      callback(new Error('The password can not be less than 8 digits'))
    } else {
      callback()
    }
  }

  private loginForm: LoginDto = {
    email: '',
    password: ''
  }

  private loginRules = {
    email: [{ validator: this.validateEmail, trigger: 'blur' }],
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  }

  private passwordType = 'password'
  private loading = false
  private showDialog = false
  private redirect?: string
  private otherQuery: Dictionary<string> = {}

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  created() {
    if(this.$route.query.email) {
      this.loginForm.email = this.$route.query.email as string
    }
  }

  mounted() {
    if (this.loginForm.email === '') {
      (this.$refs.email as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    }
  }

  private showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = ''
    } else {
      this.passwordType = 'password'
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus()
    })
  }

  private async handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if(valid) {
        this.loading = true
        await store.dispatch("auth/login", {
          email: this.loginForm.email,
          password: this.loginForm.password,
        } as LoginDto)
        .then((res) => {
          this.loading = false
          if(this.redirect) {
            this.$router.push({path: `${this.redirect}`})
          } else {
            this.$router.push({name: 'LandingPage'})
          }
        })
        .catch((error) => {
          this.loading = false
          handleError(error, true, error?.response?.request?.status == 403 ? "Invalid email or password." : undefined)
        })
      }
    })
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }
}
